export const environment = {
  production: false,
  targetAPI: 'https://customer-middleware.afrikpay.com/',
  agentid : '3673781012678060',
  agentpwd : '3673@8060$',
  apikey : '1511a7c032d0bda776970e5ebfd208c2',
  url : 'https://vicocus.afrikpay.com',
  id : 'vicocus.afrikpay.com',
  accepturl : 'https://vicocus.afrikpay.com/payment/web/success',
  cancelurl : 'https://vicocus.afrikpay.com/payment/web/failed',
  printurl : "http://invoice.afrikpay.com/print/",
  storage : 'home/oss'
};
